<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
          v-model="searchObj.vehicleNumber"
          placeholder="请输入园区"
          class="centerInput"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="date"
          type="week"
          class="centerInput"
          format="yyyy 第 WW 周"
          placeholder="请选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="searchObj.dataType"
          placeholder="请选择数据类型"
          class="centerSelInput"
        >
          <el-option
            v-for="item in dataTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <button class="centerCzbtn">搜索</button>
      </el-form-item>
    </el-form>

    <Table
      :data1="tableData"
      :columns1="columns"
      :total="tableData.length"
      @getAjax="getAjax"
      :loading="loading"
    />

    <!-- 下载弹框 -->
    <el-dialog title="删除" :visible.sync="isDownload" width="30%">
      <span>确认下载该数据包</span>
      <span slot="footer" class="dialog-footer">
        <button class="centerCzbtn mr20">确 定</button>
        <button class="centerBtn resetbtn" @click="isDownload = false">
          取 消
        </button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";

export default {
  components: {
    Table,
  },
  data() {
    return {
      searchObj: {
        park: "武汉东风汽车园区",
        date: "",
        dataType: "",
      },
      dataTypeList: [],
      loading: false,
      isDownload: false,
      tableData: [],
      columns: [
        {
          label: "项目名称",
          prop: "entryName",
        },
        {
          label: "项目类型",
          prop: "dataTypeStr",
        },
        {
          label: "上传时间",
          prop: "uploadDate",
        },
        {
          label: "接收时间",
          prop: "receiveDate",
        },
        {
          label: "大小",
          prop: "size",
        },
        {
          label: "操作",
          // fixed: "right",
          width: 250,
          render: (h, params) => {
            return h("div", [
              h(
                "span",
                {
                  class: "operationA",
                  on: {
                    click: () => {
                      this.isDownload = true;
                    },
                  },
                },
                "下载"
              ),
              h(
                "span",
                {
                  class: "operationA ml20",
                  on: {
                    click: () => {
                      this.$router.push("/dataManagementDetailDetail");

                      window.sessionStorage.setItem(
                        "dataTypeStr",
                        params.row.dataTypeStr
                      );
                      //   let data = [
                      //     params.row.dataTypeStr,
                      //     params.row.uploadDate,
                      //   ];
                      window.sessionStorage.setItem(
                        "data",
                        params.row.entryName
                      );
                      window.sessionStorage.setItem(
                        "dataTwo",
                        params.row.uploadDate
                      );
                    },
                  },
                },
                "在线查看"
              ),
            ]);
          },
        },
      ],
    };
  },
  mounted() {
    this.getTableDate();
  },
  methods: {
    //造假数据
    getTableDate() {
      this.tableData = [
        {
          entryName:
            sessionStorage.getItem("parkName") + "2021-10-01车辆数据服务包",
          dataTypeStr: "设备数据",
          uploadDate: "2021-10-01 15:12:11",
          receiveDate: "2021-10-01 15:52:11",
          size: "197条",
        },
        {
          entryName:
            sessionStorage.getItem("parkName") + "2021-10-12车辆数据服务包",
          dataTypeStr: "业务数据",
          uploadDate: "2021-10-12 10:34:23",
          receiveDate: "2021-10-12 11:02:31",
          size: "182条",
        },
        {
          entryName:
            sessionStorage.getItem("parkName") + "2021-10-17车辆数据服务包",
          dataTypeStr: "设备数据",
          uploadDate: "2021-10-17 08:01:22",
          receiveDate: "2021-10-17 08:46:31",
          size: "156条",
        },
        {
          entryName:
            sessionStorage.getItem("parkName") + "2021-10-22车辆数据服务包",
          dataTypeStr: "设备数据",
          uploadDate: "2021-10-22 21:23:24",
          receiveDate: "2021-10-22 21:54:22",
          size: "224条",
        },
        {
          entryName:
            sessionStorage.getItem("parkName") + "2021-10-22车辆数据服务包",
          dataTypeStr: "设备数据",
          uploadDate: "2021-10-26 19:11:23",
          receiveDate: "2021-10-26 19:45:56",
          size: "74条",
        },
      ];
    },
  },
};
</script>

<style>
</style>